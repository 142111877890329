import { ErrorMessage } from 'components/organisms/PageErrorMessage';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';

const Custom404Page = () => {
  const { locale } = useRouter();

  return (
    <div className="mb-[-6rem]">
      <ErrorMessage
        code={  
          locale?.startsWith('ja') ? 'ページが見つかりません' : 'Page Not Found'
        }
        title={
          locale?.startsWith('ja')
            ? 'ご指定のページが移動または\n削除された可能性があります。'
            : 'The page you specified may have been moved or deleted.'
        }
      />
    </div>
  );
};

export default Custom404Page;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', ['common'])),
    },
  };
};
